import isNil from "lodash/isNil";
import { Fill, Stroke, Text, Style } from "ol/style";

import Geometry, { GEOM_TYPES } from "../geometry/Geometry.service";

import CommonStyles, {
  STROKE_COLOR,
  FILL_COLOR,
  TEXT_COLOR,
} from "./CommonStyles.service";

export default class CommonPolygonStyle {
  static getDefault(color) {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: color
          ? `rgba(${color.red}, ${color.green}, ${color.blue}, 1)`
          : STROKE_COLOR.DEFAULT,
        width: 2,
      },
      fill: {
        color: color
          ? `rgba(${color.red}, ${color.green}, ${color.blue}, 0.15)`
          : FILL_COLOR.DEFAULT,
      },
      zIndex: 1,
      geometry: geometryFilter,
    });
  }

  static getHovered(color) {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: color
          ? `rgba(${color.red}, ${color.green}, ${color.blue}, 1)`
          : STROKE_COLOR.DEFAULT,
        width: 1,
      },
      fill: {
        color: color
          ? `rgba(${color.red}, ${color.green}, ${color.blue}, 0.75)`
          : FILL_COLOR.HOVERED,
      },
      zIndex: 2,
      geometry: geometryFilter,
    });
  }

  static getDisabled() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.DISABLED,
        width: 1,
      },
      fill: {
        color: FILL_COLOR.DISABLED,
      },
      zIndex: 0,
      geometry: geometryFilter,
    });
  }

  static getDetail(color) {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: color
          ? `rgba(${color.red}, ${color.green}, ${color.blue}, ${
              !isNil(color.strokeAlfa) ? color.strokeAlfa : 1
            })`
          : STROKE_COLOR.DEFAULT,
        width: 3,
      },
      fill: {
        color: color
          ? `rgba(${color.red}, ${color.green}, ${color.blue}, ${
              !isNil(color.fillAlfa) ? color.fillAlfa : 0.85
            })`
          : FILL_COLOR.DETAIL,
      },
      zIndex: 3,
      geometry: geometryFilter,
    });
  }

  static getSelected() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.SELECTED,
        width: 3,
      },
      fill: {
        color: FILL_COLOR.SELECTED,
      },
      zIndex: 2,
      geometry: geometryFilter,
    });
  }

  static getAvailable() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.AVAILABLE,
        width: 1,
      },
      fill: {
        color: FILL_COLOR.AVAILABLE,
      },
      zIndex: 2,
      geometry: geometryFilter,
    });
  }

  static getReachable() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.REACHABLE,
        width: 1,
      },
      fill: {
        color: FILL_COLOR.REACHABLE,
      },
      zIndex: 2,
      geometry: geometryFilter,
    });
  }

  static getDrawing() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.SELECTED,
        width: 3,
      },
      fill: {
        color: FILL_COLOR.DRAWING,
      },
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.SELECTED },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
      zIndex: 0,
      geometry: geometryFilter,
    });
  }

  static getDrawingReduction() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.DEFAULT,
        width: 3,
        lineDash: [5, 10],
      },
      fill: { color: FILL_COLOR.REDUCTION },
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.SELECTED },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
      geometry: geometryFilter,
    });
  }

  static getDrawn() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.SELECTED,
        width: 3,
      },
      fill: {
        color: FILL_COLOR.DRAWING,
      },
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.SELECTED },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
      geometry: geometryFilter,
    });
  }

  static getDrawnError() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.ERROR,
        width: 3,
      },
      fill: { color: FILL_COLOR.ERROR },
      image: {
        radius: 6,
        fill: { color: FILL_COLOR.ERROR },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
      geometry: geometryFilter,
    });
  }

  static getTransparent() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: FILL_COLOR.TRANSPARENT,
        width: 0,
      },
      fill: { color: FILL_COLOR.TRANSPARENT },
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.SELECTED },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
      geometry: geometryFilter,
    });
  }

  static getLpis(type = "default", blockName = "") {
    const isPublicLpis = type === "public";
    const LPIS_COLOR = isPublicLpis
      ? STROKE_COLOR.LPIS_PUBLIC
      : STROKE_COLOR.LPIS;
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: LPIS_COLOR,
        width: 1,
      },
      fill: {
        color: FILL_COLOR.LPIS,
      },
      ...(isPublicLpis && {
        text: new Text({
          text: blockName,
          font: "bold 12px Arial",
          fill: new Fill({
            color: TEXT_COLOR.LPIS_PUBLIC,
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
        }),
      }),
      zIndex: 1,
      geometry: geometryFilter,
    });
  }

  static getProtectionZones(zoneProtectionLevel) {
    const protectionLevelsColors = {
      1: {
        strokeColor: STROKE_COLOR.WATER_PROTECTION_ZONE_1,
        fillColor: FILL_COLOR.WATER_PROTECTION_ZONE_1,
        zIndex: 9,
      },
      2: {
        strokeColor: STROKE_COLOR.WATER_PROTECTION_ZONE_2,
        fillColor: FILL_COLOR.WATER_PROTECTION_ZONE_2,
        zIndex: 8,
      },
      3: {
        strokeColor: STROKE_COLOR.WATER_PROTECTION_ZONE_3,
        fillColor: FILL_COLOR.WATER_PROTECTION_ZONE_3,
        zIndex: 7,
      },
    };
    const { fillColor, strokeColor, zIndex } =
      protectionLevelsColors[zoneProtectionLevel] || {};

    return CommonStyles.getGeometryStyle({
      stroke: {
        color: strokeColor,
        width: 2,
      },
      fill: {
        color: fillColor,
      },
      text: new Text({
        text: zoneProtectionLevel,
        font: "500 12px Roboto, sans-serif",
        fill: new Fill({
          color: "#000",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 2,
        }),
      }),
      zIndex,
      geometry: geometryFilter,
    });
  }

  static getLandWaterBoundaryStyle() {
    return new Style({
      stroke: new Stroke({
        color: "#0000FF",
        width: 1,
      }),
    });
  }

  static getCountyStyle() {
    return new Style({
      stroke: new Stroke({
        color: "#FF0000",
        width: 1,
      }),
    });
  }

  static getBuffer() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.DEFAULT,
        width: 4,
      },
      fill: {
        color: FILL_COLOR.DEFAULT,
      },
      geometry: geometryFilter,
    });
  }

  static getMeasurement() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.MEASUREMENT_LINE,
        width: 2,
        lineDash: [4, 4],
      },
      fill: {
        color: FILL_COLOR.MEASUREMENT,
      },
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.MEASUREMENT },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
      geometry: geometryFilter,
    });
  }
}

const geometryFilter = (feature) => {
  const geometry = feature.getGeometry();
  if (Geometry.getOLType(geometry) === GEOM_TYPES.LINESTRING) {
    return null;
  }
  return geometry;
};
