import React, { Fragment } from "react";

import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";

import { AbsoluteParcelSubtractableAreas } from "../../components/AbsoluteParcelSubtractableAreas/AbsoluteParcelSubtractableAreas";
import { BoundaryParcelSubtractableAreas } from "../../components/BoundaryParcelSubtractableAreas/BoundaryParcelSubtractableAreas";
import { WaterParcelSubtractableAreas } from "../../components/WaterParcelSubtractableAreas/WaterParcelSubtractableAreas";
import { GroundWaterProtectionZones } from "../../components/WaterProtectionZones/GroundWaterProtectionZones";
import { SurfaceWaterProtectionZones } from "../../components/WaterProtectionZones/SurfaceWaterProtectionZones";

type Props = {
  isEditing: boolean;
  isEphAction: boolean;
  maxValue: number;
  onParcelSubtractionDelete: (type: string, value: number) => void;
  parcelIndex: number;
};

const ActionParcelSubtractableAreas = ({
  isEditing,
  isEphAction,
  maxValue,
  onParcelSubtractionDelete,
  parcelIndex,
}: Props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <AbsoluteParcelSubtractableAreas
        isEditing={isEditing}
        maxValue={maxValue}
        onParcelSubtractionDelete={onParcelSubtractionDelete}
        parcelIndex={parcelIndex}
      />
      <Divider className={classes.divider} />
      <BoundaryParcelSubtractableAreas
        isEditing={isEditing}
        maxValue={maxValue}
        onParcelSubtractionDelete={onParcelSubtractionDelete}
        parcelIndex={parcelIndex}
      />
      <Divider className={classes.divider} />
      <WaterParcelSubtractableAreas
        isEditing={isEditing}
        maxValue={maxValue}
        onParcelSubtractionDelete={onParcelSubtractionDelete}
        parcelIndex={parcelIndex}
      />
      {isEphAction && (
        <Fragment>
          <Divider className={classes.divider} />
          <SurfaceWaterProtectionZones
            isEditing={isEditing}
            maxValue={maxValue}
            parcelIndex={parcelIndex}
          />
          <Divider className={classes.divider} />
          <GroundWaterProtectionZones
            isEditing={isEditing}
            maxValue={maxValue}
            parcelIndex={parcelIndex}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export { ActionParcelSubtractableAreas };

const useStyles = makeStyles(() => ({
  divider: {
    margin: 10,
  },
}));
