import { useEffect } from "react";

import { useField, useFormikContext } from "formik";

import { RestrictionType } from "../../../../generated/api/agroevidence";
import { AnyTodo } from "../../../../types";
import { driftClassValue } from "../actionEph.services";

import { InitialParcelToAdd } from "../../ActionOthers/actionOther.types";
import {
  ActionEphFormValues,
  EphPlantProtectionsType,
} from "../actionEph.types";

const useEphRestrictions = () => {
  const { setFieldValue } = useFormikContext<ActionEphFormValues>();

  const [parcels] = useField(`parcels`);
  const [plantProtectionsFormValue] = useField(`plantProtections`);
  const plantProtections =
    plantProtectionsFormValue.value as EphPlantProtectionsType[];

  const buffersWithDriftReduction = plantProtections
    .map((por) =>
      por.pests
        ?.map((pest) =>
          pest.buffer
            ?.filter(
              (b) =>
                b.driftReduction ===
                driftClassValue[
                  (por.driftClass as AnyTodo)
                    ?.code as keyof typeof driftClassValue
                ],
            )
            ?.map((bufferItem) => ({
              type: bufferItem?.type,
              value: bufferItem?.buffer,
              isMaterial: true,
              isAdded: true,
              name: por.name,
            })),
        )
        ?.reduce((acc, buffers) => acc.concat(buffers ?? []), []),
    )
    ?.reduce((acc, buffers) => acc.concat(buffers ?? []), []);

  const waterProtectionZonesBuffers = plantProtections
    .map((por) =>
      por.pests
        ?.map((pest) =>
          pest.buffer
            ?.filter(
              (b) =>
                b.type === RestrictionType.SurfaceWaterProtectionZones ||
                b.type === RestrictionType.GroundWaterProtectionZones,
            )
            ?.map((bufferItem) => ({
              type: bufferItem?.type,
              value: bufferItem?.protectionLevel,
              isMaterial: true,
              isAdded: true,
              name: por.name,
            })),
        )
        ?.reduce((acc, buffers) => acc.concat(buffers ?? []), []),
    )
    ?.reduce((acc, buffers) => acc.concat(buffers ?? []), []);

  const waterRestrictions = buffersWithDriftReduction.filter(
    (buffer) => buffer.type === RestrictionType.Water,
  );
  const boundaryRestrictions = buffersWithDriftReduction.filter(
    (buffer) => buffer.type === RestrictionType.Boundary,
  );
  const surfaceWaterProtectionZones = waterProtectionZonesBuffers.filter(
    (buffer) => buffer.type === RestrictionType.SurfaceWaterProtectionZones,
  );
  const groundWaterProtectionZones = waterProtectionZonesBuffers.filter(
    (buffer) => buffer.type === RestrictionType.GroundWaterProtectionZones,
  );

  useEffect(() => {
    Promise.all(
      parcels.value.map(async (parcel: InitialParcelToAdd, index: number) => {
        const defaultBoundaryBuffers =
          parcel?.subtractableAreas?.boundary.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          );

        const defaultWaterBuffers = parcel?.subtractableAreas?.water.filter(
          (buffer: AnyTodo) => !buffer?.isAdded,
        );

        const defaultSurfaceWaterProtectionZones =
          parcel?.subtractableAreas?.surfaceWaterProtectionZones?.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          ) ?? [];

        const defaultGroundWaterProtectionZones =
          parcel?.subtractableAreas?.groundWaterProtectionZones?.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          ) ?? [];

        await setFieldValue(`parcels[${index}]`, {
          ...parcel,
          subtractableAreas: {
            ...parcel.subtractableAreas,
            boundary: [...defaultBoundaryBuffers, ...boundaryRestrictions],
            water: [...defaultWaterBuffers, ...waterRestrictions],
            surfaceWaterProtectionZones: [
              ...defaultSurfaceWaterProtectionZones,
              ...surfaceWaterProtectionZones,
            ],
            groundWaterProtectionZones: [
              ...defaultGroundWaterProtectionZones,
              ...groundWaterProtectionZones,
            ],
          },
        });
      }),
    );
  }, [parcels?.value?.length, JSON.stringify(plantProtections)]);
};

export { useEphRestrictions };
