import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { isNil } from "lodash";

import {
  DashboardParcelTo,
  DashboardSeasonTo,
  InternalCropTo,
} from "../../../../../generated/api/satellite";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import {
  SELECT_EMPTY_OBJ,
  SelectObject,
} from "../../../../../shared/components/form/FormSelect/types";

import { CropPlaceholder } from "../../components/crops/CropPlaceholder";
import { CropsDropdown } from "../../components/crops/CropsDropdown";
import {
  satelliteDashboardKey,
  useUpdateSeasonMutation,
} from "../../../../routes/dashboard/ParcelDashboard.api";
import { SeasonCellIconBar } from "./SeasonCellIconBar";

import { useQueryClient } from "@tanstack/react-query";
import { useDelayedLoader } from "../../../../hooks/useDelayedLoader";

type Props = {
  parcel?: DashboardParcelTo;
  parcelId?: string;
  cropsOptions: SelectObject<InternalCropTo>[];
  season: DashboardSeasonTo;
  // hasAssignedParcel: boolean;
};

const SeasonCell = ({
  cropsOptions,
  parcel,
  parcelId,
  season,
  // hasAssignedParcel,
}: Props) => {
  const classes = useStyles();
  const loaderClasses = useLoaderStyles();
  const [edit, setEdit] = useState(false);

  const queryClient = useQueryClient();
  const mutation = useUpdateSeasonMutation();

  const [value, setValue] = useState<
    SelectObject<InternalCropTo> | undefined
  >();
  const [mutatedParcel, setMutatedParcel] = useState<
    DashboardParcelTo | undefined
  >();

  const { isDelayed } = useDelayedLoader(mutation.isPending, 600);
  const parcelToUse = mutatedParcel ?? parcel;

  // TODO: use "hasAssignedParcel" with optimistic updates
  const parcelIsDefinedInSeason =
    (isNil(parcelId) && !isNil(parcel)) || !isNil(mutatedParcel);

  useEffect(() => {
    const selectedCrop =
      parcel && cropsOptions.find((c) => c?.obj?.name === parcel?.cropName);
    const selectedDropdownValue = selectedCrop
      ? ({
          key: selectedCrop?.obj?.id,
          value: selectedCrop?.obj?.name,
          obj: selectedCrop?.obj,
        } as SelectObject<InternalCropTo>)
      : undefined;

    setValue(selectedDropdownValue);
  }, [cropsOptions, parcel]);

  const handleChange = (
    e: React.SyntheticEvent,
    selected?: SelectObject<InternalCropTo>,
  ) => {
    setValue(selected ?? SELECT_EMPTY_OBJ);

    mutation.mutate(
      {
        cropInternalId: selected?.obj?.id ?? "",
        parcelId: parcelId ?? parcel?.parcelId ?? "",
        seasonId: season.seasonId ?? "",
        yieldInTonnes: parcel?.yieldInTonnes,
      },
      {
        onSuccess: (data) => {
          const respParcels = data?.data?.parcels ?? [];
          if (respParcels.length) {
            setMutatedParcel(respParcels[0]);
          }
          queryClient.invalidateQueries({
            queryKey: satelliteDashboardKey,
            refetchType: "inactive",
          });
        },
        onError: () => {
          setValue(undefined);
        },
      },
    );
  };

  let Jsx = null;

  if (isDelayed) {
    Jsx = <CfLoader size={32} classes={loaderClasses} />;
  } else if (edit) {
    Jsx = (
      <CropsDropdown
        cropsOptions={cropsOptions}
        setEdit={setEdit}
        value={value}
        handleChange={handleChange}
      />
    );
  } else if (!edit) {
    Jsx = (
      <CropPlaceholder onClick={() => setEdit(true)} selectedOption={value} />
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        {Jsx}
        {!isDelayed && (
          <div className={classes.subText}>
            {isNil(parcelToUse?.yieldInTonnes)
              ? "-"
              : `${parcelToUse?.yieldInTonnes} t/ha`}
          </div>
        )}
      </div>
      {parcelIsDefinedInSeason && parcelToUse && (
        <SeasonCellIconBar
          data={parcelToUse}
          products={parcelToUse?.products ?? []}
        />
      )}
    </div>
  );
};

export const useLoaderStyles = makeStyles(() => ({
  wrapper: {
    justifyContent: "flex-start",
  },
}));

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  subText: {
    fontSize: 12,
  },
  header: {
    width: "100%",
  },
}));

export { SeasonCell };
