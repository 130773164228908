import React from "react";

import regionPinIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/region-pin.svg";

import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import { getShortDateString } from "../../../../../shared/misc/timeHelpers";
import { DashboardSeasonTo } from "../../../../../generated/api/satellite";

type Props = {
  season: DashboardSeasonTo;
};

const SeasonsTableHeaderCell = ({ season }: Props) => {
  const intl = useTypedIntl();
  const classes = useStyles();

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.headerMain}>{season.name}</div>
      <div
        className={classes.headerSub}
      >{`${intl.formatMessage({ id: `PrecisionFarming.seasons.addSeason.start` })}: ${getShortDateString(season.dateFrom, "DD.MM.YYYY")}`}</div>
      <div className={classes.headerRegion}>
        <span>
          <img alt="combine" src={regionPinIcon} style={{ display: "flex" }} />
        </span>
        <span
          className={classes.headerArea}
        >{`${intl.formatMessage({ id: "PrecisionFarming.seasons.totalArea" })} ${season.totalAreaHa} ha`}</span>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  headerMain: {
    color: "#00A179",
    fontSize: 16,
  },
  headerSub: {
    color: "#00A179",
    fontSize: 12,
  },
  headerRegion: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  headerArea: {
    fontWeight: 400,
  },
}));

export { SeasonsTableHeaderCell as TableHeaderCell };
