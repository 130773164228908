import React, { useState } from "react";

import { Theme, Button, IconButton, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";

import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { FormattedMessage } from "react-intl";

import { NewSeasonForm } from "./NewSeasonForm";
import { parcelDashboardQuery } from "./ParcelDashboard.api";

import NoPrecision from "../../../core/precision/containers/NoPrecision";
import { useFarmIds } from "../../../shared/api/client.utils";

import classNames from "classnames";
import { useTablePaging } from "./components/table/useTablePaging";
import { Table } from "./components/table/Table";
import { TableFooter } from "../../components/TableFooter";
import { AdvancedFilterType } from "../../types";
import { AdvancedFilter } from "../../components/advancedFilter/AdvancedFilter";
import { useCropOptions } from "../services/hooks/useCropOptions";
import CfTextFieldFilter from "../../../common/components/CfTextFieldFilter/CfTextFieldFilter";
import { COLOR_PRIMARY } from "../../../theme";

const hasPages = (totalCount: number, perPageCount: number) =>
  Math.ceil(totalCount / perPageCount) > 1;

const isOffsetEnd = (
  totalCount: number,
  currentOffset: number,
  perPageCount: number,
): boolean => {
  const currentIsLast = currentOffset + perPageCount >= totalCount;
  return currentIsLast;
};

type Props = {
  langId: LANGUAGE_ID;
  showListing: () => void;
};

const Dashboard = ({ langId, showListing }: Props) => {
  const { farmIds } = useFarmIds();
  const tableClasses = useStyles();
  const toolbarClasses = useToolbarStyles();

  const hasPrecision = true;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [textFilter, setTextFilter] = useState("");
  const [advancedFilter, setAdvancedFilter] = useState<AdvancedFilterType>({});

  const { crops } = useCropOptions();

  const {
    seasonsPerPage,
    seasonsOffset,
    setSeasonsOffset,
    parcelsPage,
    parcelsPerPage,
    setParcelsPage,
    setParcelsPerPage,
  } = useTablePaging();

  const filteredCrops = advancedFilter?.crops?.map((c) => c.id);
  const {
    data: dashboard,
    isFetching,
    isLoading,
    error,
  } = useQuery(
    parcelDashboardQuery({
      farmIds,
      "per-page": parcelsPerPage,
      "sort-dir": "asc",
      page: parcelsPage + 1,
      search: textFilter,
      "seasons-offset": seasonsOffset,
      "seasons-per-page": seasonsPerPage,
      crops: filteredCrops,
    }),
  );

  const inProgress = isFetching || isLoading;
  const dashboardData = dashboard?.data;
  const parcelsCount = dashboard?.parcelsCount ?? 0;
  const seasonsCount = dashboard?.seasonsCount ?? 0;

  const toggleDrawer =
    (open: boolean) => (event?: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setParcelsPerPage(newRowsPerPage);
    setParcelsPage(0);
  };

  let Jsx = null;

  if (hasPrecision !== undefined && !hasPrecision) {
    Jsx = <NoPrecision langId={langId} />;
  } else {
    const hasColumnPages =
      !isFetching && hasPages(seasonsCount, seasonsPerPage);
    const isLastSeasonPage = isOffsetEnd(
      seasonsCount,
      seasonsOffset,
      seasonsPerPage,
    );

    const dashboardParcels = dashboardData?.parcels ?? [];
    const dashboardSeasons = dashboardData?.seasons ?? [];
    const noParcelsForSeasons = !!(
      dashboardSeasons.length && !dashboardParcels.length
    );

    Jsx = (
      <div>
        <div className={toolbarClasses.header}>
          <Button
            onClick={showListing}
            size="large"
            className={toolbarClasses.backToDetail}
            variant="text"
            sx={{
              textTransform: "unset",
            }}
          >
            <span className={toolbarClasses.button}>
              <KeyboardArrowLeftIcon sx={{ mr: 1 }} />
              <FormattedMessage id="PrecisionFarming.seasons.backToList" />
            </span>
          </Button>
          <div className={toolbarClasses.toolbar}>
            <div className={toolbarClasses.leftSide}>
              <CfTextFieldFilter
                initialValue={textFilter}
                name="precision-list-text-filter"
                onChange={setTextFilter}
                translId="PrecisionFarming.seasons.table.filter"
              />
              <AdvancedFilter
                crops={crops}
                advancedFilter={advancedFilter}
                setAdvancedFilter={setAdvancedFilter}
              />
            </div>
            <Button
              color="secondary"
              onClick={toggleDrawer(true)}
              size="small"
              style={{ minHeight: 48 }}
              variant="outlined"
              sx={{
                textTransform: "unset",
                padding: "11px 16px",
              }}
              classes={{
                root: toolbarClasses.addSeasonRoot,
              }}
            >
              <span className={toolbarClasses.button}>
                <AddIcon sx={{ mr: 1 }} />
                <span className={toolbarClasses.addSeason}>
                  <FormattedMessage id="PrecisionFarming.seasons.addSeason.openButton" />
                </span>
              </span>
            </Button>
          </div>
        </div>
        <div className={tableClasses.tableWrapper}>
          <Table
            dashboardParcels={dashboardParcels}
            dashboardSeasons={dashboardSeasons}
            noParcelsForSeasons={noParcelsForSeasons}
            isFetching={inProgress}
            prevIcon={
              <IconButton
                onClick={() => setSeasonsOffset((prev) => prev - 1)}
                disabled={!seasonsOffset}
                size="small"
                className={classNames({
                  [tableClasses.arrowButton]: true,
                  [tableClasses.hasNoColumnPagesArrow]: !hasColumnPages,
                  [tableClasses.noParcelsForSeasonsArrow]: noParcelsForSeasons,
                })}
              >
                <WestIcon htmlColor={"#FFFFFF"} />
              </IconButton>
            }
            nextIcon={
              <IconButton
                onClick={() => setSeasonsOffset((prev) => prev + 1)}
                className={classNames({
                  [tableClasses.arrowButton]: true,
                  [tableClasses.hasNoColumnPagesArrow]: !hasColumnPages,
                })}
                disabled={isLastSeasonPage}
                size="small"
              >
                <EastIcon htmlColor={"#FFFFFF"} />
              </IconButton>
            }
          />
          <div className={tableClasses.footerContainer}>
            {!inProgress && (
              <TableFooter
                count={parcelsCount}
                page={parcelsPage}
                rowsPerPage={parcelsPerPage}
                setPage={setParcelsPage}
                setRowsPerPage={handleRowsPerPageChange}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <CfErrorPage error={error ?? undefined}>
      <div className={tableClasses.wrapper}>
        {Jsx}
        <NewSeasonForm
          open={isDrawerOpen}
          onCloseClick={toggleDrawer(false)}
          page={parcelsPage}
        />
      </div>
    </CfErrorPage>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    height: "100%",
    position: "relative",
  },
  tableWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  footerContainer: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
  },
  arrowButton: {
    position: "absolute",
    right: 0,
    bottom: 0,
    transform: "translateY(100%)",
    borderRadius: "100%",
    backgroundColor: alpha(COLOR_PRIMARY.cleverfarm.main, 0.8),
    "&:hover": {
      backgroundColor: alpha(COLOR_PRIMARY.cleverfarm.main, 0.65),
    },
  },
  noParcelsForSeasonsArrow: {
    left: 0,
    right: "unset",
  },
  hasNoColumnPagesArrow: {
    display: "none",
  },
}));

export const useToolbarStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    flexGrow: 1,
    width: "100%",
    gap: 16,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
  },
  backToDetail: {
    fontSize: 24,
    padding: "0 11px 0 0",
    borderRadius: 8,
  },
  addSeasonRoot: {
    borderRadius: 8,
  },
  addSeason: {
    lineHeight: 1.25,
    marginTop: 2,
    fontSize: 16,
    fontWeight: 500,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
    gap: 10,
  },
  leftSide: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
    gap: 12,
  },
}));

export { Dashboard };
