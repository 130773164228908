import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import _ from "lodash";
import {
  DashboardParcelTo,
  SeasonTo,
} from "../../../../generated/api/satellite";
import { useDelayedLoader } from "../../../hooks/useDelayedLoader";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import { YieldInput } from "./YieldInput";
import { isNil } from "lodash";
import { useUpdateSeasonParcelMutation } from "../PrecisionServices.api";
import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";

type Props = {
  parcel: DashboardParcelTo;
  season?: SeasonTo;
  servicesQueryKey: (string | number | string[] | undefined)[];
};

const YieldCell = ({ parcel, season, servicesQueryKey }: Props) => {
  const classes = useStyles();
  const yieldClasses = useYieldInputStyles();
  const loaderClasses = useLoaderStyles();
  const [edit, setEdit] = useState(false);

  const seasonParcelMutation = useUpdateSeasonParcelMutation({
    servicesQueryKey,
    parcelId: parcel.parcelId,
  });

  const { isDelayed } = useDelayedLoader(seasonParcelMutation.isPending, 600);

  const handleChange = (e: React.SyntheticEvent, newYield?: number) => {
    e.stopPropagation();

    seasonParcelMutation.mutate({
      cropInternalId: parcel.cropInternalId ?? "",
      cropName: parcel.cropName ?? "",
      parcelId: parcel.parcelId,
      seasonId: season?.id ?? "",
      yieldInTonnes: newYield,
    });
  };

  let Jsx = null;

  if (isDelayed) {
    Jsx = <CfLoader classes={loaderClasses} size={32} />;
  } else if (edit) {
    Jsx = (
      <YieldInput
        classes={yieldClasses}
        handleChange={handleChange}
        setEdit={setEdit}
        value={parcel.yieldInTonnes}
      />
    );
  } else if (!edit) {
    Jsx = (
      <div
        className={classes.placeholder}
        onClick={(e) => {
          e.stopPropagation();
          setEdit(true);
        }}
      >
        <span className={classes.placeholderValue}>
          {isNil(parcel.yieldInTonnes) ? (
            "-"
          ) : (
            <CfFormattedNumber
              decimalDigits={1}
              value={parcel.yieldInTonnes || 0}
            />
          )}
          <span>t/ha</span>
        </span>
      </div>
    );
  }

  return <div className={classes.wrapper}>{Jsx}</div>;
};

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
  },
  placeholder: {
    height: 56,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "12ch",
    paddingRight: "1ch",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#0000000a",
    },
  },
  placeholderValue: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "0.7ch",
  },
}));

const useLoaderStyles = makeStyles(() => ({
  wrapper: {
    justifyContent: "flex-start",
  },
}));

const useYieldInputStyles = makeStyles(() => ({
  formControl: {
    maxWidth: "12ch",
  },
  input: {
    textAlign: "end",
  },
}));

export { YieldCell };
