import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import {
  DashboardSeasonTo,
  deleteParcelFromSeason,
  getSeasonDashboard,
  GetSeasonDashboardParams,
  InternalCropTo,
  updateSeasonParcelMetadata,
} from "../../../generated/api/satellite";
import { getCountedData, useFarmIds } from "../../../shared/api/client.utils";
import { staleTime } from "../../../shared/query-client";

const satelliteServicesKey = ["satellite", "services"];

const servicesQuery = (seasonId: string, params: GetSeasonDashboardParams) =>
  queryOptions({
    queryKey: [...satelliteServicesKey, ...Object.values(params), seasonId],
    queryFn: async () => {
      const data = await getSeasonDashboard(seasonId, params);
      return getCountedData(data);
    },
    staleTime: staleTime.Infinity,
  });

const useServicesDelete = () =>
  useMutation({
    mutationFn: (params: Parameters<typeof deleteParcelFromSeason>) =>
      deleteParcelFromSeason(...params),
  });

type UseUpdateSeasonType = {
  servicesQueryKey: (string | number | string[] | undefined)[];
  parcelId: string;
};

const useUpdateSeasonParcelMutation = ({
  servicesQueryKey,
  parcelId,
}: UseUpdateSeasonType) => {
  const farmIds = useFarmIds();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      payload: Parameters<typeof updateSeasonParcelMetadata>[0] & {
        cropName: string;
      },
    ) => updateSeasonParcelMetadata(payload, farmIds),
    onMutate: (params) => {
      const snapshot = queryClient.getQueriesData({
        queryKey: servicesQueryKey,
      });

      queryClient.setQueryData(
        servicesQueryKey,
        (prev: { data: DashboardSeasonTo[]; count: number }) => {
          const resData = prev.data.map((item) => {
            return {
              ...item,
              parcels: item.parcels.map((parcel) =>
                parcel.parcelId === parcelId
                  ? {
                      ...parcel,
                      cropName: params.cropName,
                      cropInternalId: params.cropInternalId,
                      yieldInTonnes: params.yieldInTonnes,
                    }
                  : parcel,
              ),
            };
          });

          return {
            ...prev,
            data: resData,
          };
        },
      );

      return () => {
        queryClient.setQueryData(servicesQueryKey, snapshot);
      };
    },
    onError: (error, variables, rollback) => {
      rollback?.();
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: servicesQueryKey,
      });
    },
  });
};

export {
  satelliteServicesKey,
  servicesQuery,
  useServicesDelete,
  useUpdateSeasonParcelMutation,
};
